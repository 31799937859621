
/* 
=================
IMAGE BACKGROUND
=====================
*/


body{

  background-image: linear-gradient(#F8F8FF, white);
  background-repeat: no-repeat;
}


/* 
=================
Table
=====================
*/

.table_container {
  max-width: 750px;
  /* max-height: 500px; */
  margin: 0 auto;
  overflow: auto;
}

.table {
  width: 100%;
  border-spacing: 0;
}

caption {
  font-style: italic;
  font-size: 90%;
  margin-bottom: 10px;
}

.table th {
  background: #fff;
  padding: 8px 10px;
  border-bottom: 1px solid #1a1a1a;
  font-weight: bold;
  text-align: left;
}

.table th.up {
  background-image: url("./images/mytable/up_arrow.png");
}
.table th.down {
  background-image: url("./images/mytable/down_arrow.png");
}
.table th.default {
  background-image: url("./images/mytable/default.png");
}
th.up,
th.default,
th.down {
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: center right;
}

.table td {
  border-top: 1px solid #ddd;
  padding: 8px 20px;
}

.table tbody tr:first-child td {
  border-top: none;
}

.table tbody tr:nth-child(n) td {
  background: #eff0f0;
}

.table tbody tr:nth-child(2n) td {
  background: #fff;
}









a:hover,a:focus{
  text-decoration: none;
  outline: none;
}
#accordion .panel{
  border: none;
  border-radius: 0;
  box-shadow: none;
  margin-bottom: 15px;
  position: relative;
}
#accordion .panel:before{
  content: "";
  display: block;
  width: 1px;
  height: 100%;
  border: 1px dashed #6e8898;
  position: absolute;
  top: 25px;
  left: 18px;
}
#accordion .panel:last-child:before{ display: none; }
#accordion .panel-heading{
  padding: 0;
  border: none;
  border-radius: 0;
  position: relative;
}
#accordion .panel-title a{
  display: block;
  padding: 10px 30px 10px 60px;
  margin: 0;
  background: #fff;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 1px;
  color: #1d3557;
  border-radius: 0;
  position: relative;
}
#accordion .panel-title a:before,
#accordion .panel-title a.collapsed:before{
  content: "\f107";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  width: 40px;
  height: 100%;
  line-height: 40px;
  background: #8a8ac3;
  border: 1px solid #8a8ac3;
  border-radius: 3px;
  font-size: 17px;
  color: #fff;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.3s ease 0s;
}
#accordion .panel-title a.collapsed:before{
  content: "\f105";
  background: #fff;
  border: 1px solid #6e8898;
  color: #000;
}
#accordion .panel-body{
  padding: 10px 30px 10px 30px;
  margin-left: 40px;
  background: #fff;
  border-top: none;
  font-size: 15px;
  color: #6f6f6f;
  line-height: 28px;
  letter-spacing: 1px;
}

.card-header.collapsed {
  background:#F0F8FF;
}
.card-header:not(.collapsed) {
  background:#F0F8FF;
}